import React from "react";
import { Link } from "gatsby";
import starIcon from "../../assets/images/star-icon.png";
import shape1 from "../../assets/images/shape/circle-shape1.png";
import { StaticImage } from "gatsby-plugin-image";

const CirculatingSupply = () => {
  return (
    <section className="about-area pt-100 pb-100 ">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-image">
              <StaticImage
                placeholder="blurred"
                alt="Staking Economy"
                src={"../../assets/images/staking-economy.jpg"}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="content">
                <span className="sub-title">
                  <img style={{ width: 30 }} src={starIcon} alt="about" />
                  Limited Supply
                </span>
                <h2>Circulating Supply of just 8M</h2>
                <p>
                  $HYDRA is among the most scarce assets out there, having less
                  than half of the circulating supply compared to Bitcoin and
                  10x less compared to Ethereum! Approximately 85% of all HYDRA
                  is staked, so actual free-float is in the ~1.2M HYDRA further
                  empowering scarcity
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="circle-shape1">
        <img src={shape1} alt="about" />
      </div>
    </section>
  );
};

export default CirculatingSupply;
