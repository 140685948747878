import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const InstitutionalInvestment = ({}) => {
  return (
    <section className="funfacts-area bg-image pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="single-funfacts-item">
              <div className="icon">
                <StaticImage
                  placeholder="blurred"
                  className={"mx-auto"}
                  layout="fixed"
                  width={60}
                  height={60}
                  src="../../assets/images/icons/apy.png"
                  alt="Annual Percentage Return"
                />
              </div>
              <div className="how-its-work-content text-white">
                <span className="sub-title text-white">
                  Institutional Investment
                </span>
                <h2>Travel Giant Webjet (WEB:ASX) Invests up to $8.3M</h2>
                <a
                  href="https://medium.com/locktrip/travel-giant-webjet-web-asx-to-invest-4-1m-into-locktrip-and-hydra-f95354cc09ee"
                  className="text-white"
                >
                  <i className="flaticon-right mr-2"></i>
                  <span>View Full Announcement</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InstitutionalInvestment;
