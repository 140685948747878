import React from "react";
import starIcon from "../../assets/images/star-icon.png";
import { StaticImage } from "gatsby-plugin-image";
import Project from "../Projects/Project";
import rezchainLogo from "../../assets/images/partner/d_partner_rezchain.png";
import evedoLogo from "../../assets/images/projects/evedo-logo.svg";

import hydraLogo from "../../assets/images/logo.png";
import goMeatLogo from "../../assets/images/projects/gomeat.png";

const ProjectsOnHydra = () => {
  const projects = [
    {
      name: "LockTrip",
      logo: "https://locktrip.com/images/locktrip_logo.svg",
      highlights: [
        "LOC Token with Deflationary Economy",
        "Working on Travel NFT Matrix",
      ],
      website: { link: "https://locktrip.com", title: "LockTrip.com" },
      community: "https://t.me/LockTrip",
    },
    {
      name: "GoMeat",
      logo: goMeatLogo,
      highlights: [
        "GOMT Token with Deflationary Economy",
        "Working on Blockchain Certificates for the meat industry",
      ],
      website: { link: "https://gomeat.io", title: "GoMeat.io" },
      community: "https://t.me/GoMeatToken",
    },
    {
      name: "Evedo",
      logo: evedoLogo,
      highlights: [
        "EVED Token preparing for migration from Ethereum to Hydra chain",
      ],
      website: { link: "https://www.evedo.co", title: "evedo.co" },
      community: "https://t.me/evedoco",
    },
    {
      name: "RezChain",
      logo: rezchainLogo,
      highlights: [
        "Booking verification system for the travel industry (exploration stage)",
      ],
      website: { link: "https://rezchain.com/", title: "rezchain.com" },
    },
    {
      name: "Hydra DeFi",
      logo: hydraLogo,
      highlights: [
        "Hydra DEX with AMM and token swapping interface",
        "Working on liquidity mining and single-sided liquidity provision",
      ],
    },
    {
      name: "🔸 Ethereum/Hydra Cross-Chain Bridge",
      logo: hydraLogo,
      highlights: [
        "Enabling capital inflow from the Ethereum ecosystem onto the Hydra ecosystem",
      ],
    },
    {
      name: "NFT Marketplace",
      logo: hydraLogo,
      highlights: ["To support the creation & trading of Hydra-based NFTs"],
    },
    {
      name: "Community DAO",
      logo: hydraLogo,
      highlights: [
        "Network-wide decisions to be made by the community through the process of voting",
      ],
    },
  ];

  return (
    <section className="about-area pt-100 pb-100 bg-f1f8fb">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <div className="about-content banner-wrapper-content text-center">
              <div className="pb-5">
                <span className="sub-title">
                  <img style={{ width: 30 }} src={starIcon} alt="about" />
                  Projects on Hydra
                </span>
                <h2>Who is building on Hydra?</h2>
              </div>

              <div class="card-columns">
                {projects.map((project) => (
                  <Project small project={project} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectsOnHydra;
