import React from "react";
import starIcon from "../../assets/images/star-icon.png";
import shape2 from "../../assets/images/our-mission/our-mission-shape2.png";
import shape1 from "../../assets/images/shape/circle-shape1.png";
import { StaticImage } from "gatsby-plugin-image";

const HighAPY = () => {
  return (
    <section className="about-area pb-100 pt-100 bg-f1f8fb">
      <div className="container">
        <div className="text-center mb-5 banner-wrapper-content">
          <span className="sub-title">
            <img style={{ width: 30 }} src={starIcon} alt="about" />
            High APY
          </span>
          <h2>60% Staking APY available</h2>
          <p className="mx-auto">
            HYDRA holders can stake their coins to earn passive income. The APR
            is currently at around 60% and is estimated to remain above 40% in
            2022.
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="about-content">
              <div className="content">
                <h4 className="pb-4">
                  For stakers with less than ⬇️ 800 HYDRA:
                </h4>
                <ul className="about-list">
                  <li>
                    <i className="flaticon-tick"></i>
                    Recommended to stake on Kucoin or MEXC pools
                    <img src={shape2} alt="about" />
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Easy interface with daily rewards
                    <img src={shape2} alt="about" />
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Slightly lower APR
                    <img src={shape2} alt="about" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="about-content">
              <div className="content">
                <h4 className="pb-4">
                  For stakers with more than ⬆️ 800 HYDRA:
                </h4>
                <ul className="about-list">
                  <li>
                    <i className="flaticon-tick"></i>
                    Recommended to deploy your own node -{" "}
                    <a
                      href="https://hydrachain.org/becoming-a-node"
                      target="_blank"
                      className="text-sm"
                    >
                      View Guide
                    </a>
                    <img src={shape2} alt="about" />
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Setup takes less than 1 hour with rewards whenever you mine
                    a block (can be irregular)
                    <img src={shape2} alt="about" />
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Slightly higher APR
                    <img src={shape2} alt="about" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="circle-shape1">
        <img src={shape1} alt="about" />
      </div>
    </section>
  );
};

export default HighAPY;
