import React from "react";
import starIcon from "../../assets/images/star-icon.png";
import shape2 from "../../assets/images/our-mission/our-mission-shape2.png";
import shape1 from "../../assets/images/shape/circle-shape1.png";

const HydraUnique = () => {
  return (
    <section className="about-area pb-100 pt-100">
      <div className="container">
        <div className="text-center mb-5 banner-wrapper-content">
          <span className="sub-title">
            <img style={{ width: 30 }} src={starIcon} alt="about" />
            truly decentralized Blockchain
          </span>
          <h2>What makes Hydra unique?</h2>
          <p className="mx-auto">
            Hydra is a truly decentralized POS (proof of stake) blockchain and
            emerged out of the combination of Bitcoin, Ethereum and Qtum. It
            unifies the best features of all three cutting-edge chains and
            introduces a unique economic layer on top. This makes Hydra not only
            cutting-edge technology, but also enables a very strong shared
            economy of which all parties can benefit fairly.
          </p>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="about-content">
              <div className="content mx-auto">
                <ul className="about-list">
                  <li>
                    <i className="flaticon-tick"></i>
                    Gas royalty for smart contract creators (incentivizing
                    projects to build on Hydra)
                    <img src={shape2} alt="about" />
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Fixed transaction fees (providing a stable and predictable
                    environment for real-world businesses)
                    <img src={shape2} alt="about" />
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Unique balance of supply. Hydra can become both inflationary
                    and deflationary
                    <img src={shape2} alt="about" />
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    High scalability on network and wallet level
                    <img src={shape2} alt="about" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="circle-shape1">
        <img src={shape1} alt="about" />
      </div>
    </section>
  );
};

export default HydraUnique;
