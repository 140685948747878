import React from "react";
import starIcon from "../../assets/images/star-icon.png";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const TransactionFeeBurn = () => {
  const image = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "fixed-fees.png" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <section className="how-its-work-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-content">
              <span className="sub-title">
                <img style={{ width: 30 }} src={starIcon} alt="choose" />
                Transaction Fee Burn
              </span>
              <h2>
                🔥 Hydra Chain burns 100% of transaction fees at protocol level
              </h2>
              <p>
                Whenever a transaction is made on Hydra chain, 100% of the net
                fees get burnt and become permanently unavailable.
              </p>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-image">
              <Img
                alt="Shared Economy"
                fluid={image.file.childImageSharp.fluid}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TransactionFeeBurn;
