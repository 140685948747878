import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import HydraGuard from "../../assets/images/hydra-guard.png";

const Banner = () => {
  return (
    <div className="seo-agency-banner py-5">
      <div className="container-fluid">
        <div className="">
          <div className="col-md-12">
            <div className="banner-wrapper-content text-center position-relative">
              <div className={"my-2"}>
                <StaticImage
                  placeholder="blurred"
                  width={150}
                  src={"../../assets/images/logo.png"}
                  alt="HydraChain"
                />
              </div>

              <div className="d-flex flex-row justify-content-center align-items-center">
                <div className="mr-5 d-none d-md-block">
                  <img
                    style={{
                      maxWidth: 150,
                      opacity: 0.2,
                      transform: "scaleX(-1)",
                    }}
                    src={HydraGuard}
                  />
                </div>
                <div>
                  <h1>Welcome to HydraChain!</h1>
                  <p className="text-center mx-auto">
                    Hydra is an open-source Proof-of-Stake blockchain with a
                    unique set of economic features. It packs a unique
                    combination of inflationary as well as deflationary
                    mechanics as part of its economy that work in parallel and
                    let the real adoption define its total supply.
                  </p>
                  <div className="btn-box">
                    <a
                      href="https://docs.google.com/document/d/e/2PACX-1vReUzdGzkc3Plm-VbLv2TGWeJ81dMmraKczCaUVRBDix3s4PROipcJ2aDQ1U_bZruU_OplXVQqCCPbe/pub"
                      className="default-btn"
                      style={{ width: 245 }}
                      target="_blank"
                    >
                      <i className="flaticon-right"></i>
                      Download White Paper
                      <span></span>
                    </a>
                    <a
                      href="https://t.me/hydrachain"
                      target="_blank"
                      rel="noreferrer"
                      className="default-btn mt-3 mt-md-0 ml-0 ml-sm-2"
                      style={{ width: 245 }}
                    >
                      <i
                        className="flaticon-team"
                        style={{ fontSize: 25, left: 10 }}
                      ></i>
                      Join the Community
                      <span></span>
                    </a>
                  </div>
                </div>
                <div className="ml-5 d-none d-md-block">
                  <img
                    style={{ maxWidth: 150, opacity: 0.2 }}
                    src={HydraGuard}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
