import React from "react";
import Footer from "../components/App/Footer";
import Layout from "../components/App/Layout";
import Banner from "../components/Landing/Banner";
import CirculatingSupply from "../components/Landing/CirculatingSupply";
import InstitutionalInvestment from "../components/Landing/InstitutionalInvestment";
import TransactionFeeBurn from "../components/Landing/TransactionFeeBurn";
import HighAPY from "../components/Landing/HighAPY";
import Partner from "../components/Projects/Partner";
import ProjectsOnHydra from "../components/Landing/ProjectsOnHydra";
import HydraUnique from "../components/Landing/HydraUnique";

const Landing = () => {
  return (
    <Layout>
      <Banner />
      <CirculatingSupply />
      <TransactionFeeBurn />
      <HighAPY />
      <InstitutionalInvestment />
      <ProjectsOnHydra />
      <HydraUnique />
      <Partner />
      <Footer />
    </Layout>
  );
};

export default Landing;
